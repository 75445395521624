@import url(https://fonts.googleapis.com/css?family=Roboto);
.flipped {
  pointer-events: none;
}

img {
  border-radius: 5px;
}

.matched {
  -webkit-animation-duration: 3s;
          animation-duration: 3s;
  -webkit-animation-name: match;
          animation-name: match;
}

.no-match {
  -webkit-animation-duration: 3s;
          animation-duration: 3s;
  -webkit-animation-name: no-match;
          animation-name: no-match;
}

.tile {
  background: #006e6d;
  border-radius: 5px;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}

.tile:hover {
  background: #005554;
  cursor: pointer;
}

.unflipped {
  -webkit-filter: opacity(0%);
          filter: opacity(0%);
}

@-webkit-keyframes match {
  from {
    box-shadow: 0px 0px 0px 4px #00a591;
  }

  to {
    box-shadow: 0;
  }
}

@keyframes match {
  from {
    box-shadow: 0px 0px 0px 4px #00a591;
  }

  to {
    box-shadow: 0;
  }
}

@-webkit-keyframes no-match {
  from {
    box-shadow: 0px 0px 0px 4px #9e1030;
  }

  to {
    box-shadow: 0;
  }
}

@keyframes no-match {
  from {
    box-shadow: 0px 0px 0px 4px #9e1030;
  }

  to {
    box-shadow: 0;
  }
}

.footer {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  grid-column: 1/3;
  font-size: 12px;
}

.pipe {
  padding: 0 5px;
}

.footer a {
  text-decoration: none;
  color: #006e6d;
  font-weight: 600;
}

.footer a:hover {
  color: #005554;
}

.App {
  height: 100%;
  font-family: Roboto;
  display: grid;
  grid-template-rows: repeat(auto, 4);
}

.board {
  display: grid;
  grid-template-columns: repeat(5, 80px);
  grid-template-rows: repeat(6, 80px);
  grid-gap: 6px;
}

.board-container {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  grid-column: 1/3;
  grid-row: 3/4;
}

.dashboard {
  grid-column: 1/3;
  display: grid;
  grid-template-columns: repeat(8, 1fr);
  font-size: 18px;
}

.header {
  font-size: 32px;
  text-align: center;
  grid-column: 1/3;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
}

.matches {
  grid-column: 4/5;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
}

.reset {
  grid-column: 5/6;
  grid-row: 1/3;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
}

.reset-btn {
  height: 40px;
  width: 80px;
  font-size: 14px;
  border-radius: 5px;
  background: #006e6d;
  color: white;
}

.reset-btn:hover {
  background: #005554;
  cursor: pointer;
}

.reset-btn:disabled,
.reset-btn[disabled] {
  border: 1px solid #999999;
  background-color: #cccccc;
  color: #666666;
}

.reset-btn[disabled]:hover {
  cursor: not-allowed;
}

.time {
  grid-column: 4/5;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
}

body,
html,
#root {
  height: 100%;
}

body {
  margin: 0;
}

